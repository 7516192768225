<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col class="titleWidth">
        <version-status-title
          title="風險評估｜"
          :versionData="versionData"
        ></version-status-title>
      </v-col>
      <v-col v-if="!isFromSignOff()" class="d-flex align-center justify-end">
        <v-btn to="/risk-evaluation/management" depressed class="mr-2"
          >返回列表</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 0 &&
            ((versionData.group == '分公司' &&
              rolesCheck('riskEvaluationByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('riskEvaluationByIdStatus')))
          "
          @click="changeStatus(1)"
          depressed
          class="mr-2"
          color="primary"
          >製作初稿</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 0 && rolesCheck('riskEvaluationByIdImport')
          "
          @click="importDialogOpen"
          depressed
          class="mr-2"
          color="primary"
          >匯入</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 1 &&
            ((versionData.group == '分公司' &&
              rolesCheck('riskEvaluationByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('riskEvaluationByIdStatus')))
          "
          @click="changeStatus(0)"
          depressed
          class="mr-2"
          color="primary"
          outlined
          >修改</v-btn
        >
      </v-col>
    </v-row>
    <div class="d-inline-flex">
      <v-select
        outlined
        dense
        v-model="riskItem"
        class="inputWhiteBg mr-4 mb-4"
        hide-details
        label="風險項目"
        clearable
        :items="riskItems"
        :menu-props="{ offsetY: true }"
        @change="getRiskEvaluation"
      ></v-select>
      <v-select
        outlined
        dense
        v-model="rrsLevel"
        class="inputWhiteBg mb-4"
        hide-details
        label="剩餘風險等級"
        clearable
        :items="rrsList"
        :menu-props="{ offsetY: true }"
        item-text="level"
        @change="getRiskEvaluation"
      ></v-select>
    </div>
    <!-- <v-data-table :headers="header" :items="item_list">
      <template v-slot:item.inherentRisk="{ item }">
        {{ item.data }}
        {{ item.data["irs"] }} ({{ item.data["irsLevel"] }})
      </template>
      <template #controlMeasures="{ item }"
        >{{ item.controlMeasures }} (中)</template
      >
      <template #residualRisk="{ item }">{{ item.residualRisk }} (高)</template>
      <template #actions="{ item }">
        <v-icon
          @click="OpenFactorCalculationDialog(item.id)"
          class="pointer"
          :disabled="versionData.status != 0"
          >mdi-pencil</v-icon
        >
      </template>
    </v-data-table> -->
    <material-table
      :headers="header"
      :items="item_list"
      :options.sync="options"
    >
      <template #inherentRisk="{ item }">
        <!-- {{ item.data?.irs }} -->
        {{ item.data?.irs || "-" }}
        {{ item.data?.irsLevel ? `(${item.data?.irsLevel})` : "" }}
      </template>
      <template #factor="{ item }">
        {{ item.factor }}
      </template>
      <template #controlMeasures="{ item }">
        {{ item.data?.cm || "-" }}
        {{ item.data?.cmLevel ? `(${item.data?.cmLevel})` : "" }}
      </template>
      <template #residualRisk="{ item }">
        {{ item.data?.rrs || "-" }}
        {{ item.data?.rrsLevel ? `(${item.data?.rrsLevel})` : "" }}
      </template>
      <template #period="{ item }">
        {{ item.data?.period || "-" }}
        <!-- {{ item.data?.rrsLevel ? `(${item.data?.rrsLevel})` : "" }} -->
      </template>
      <template #nextAuditYear="{ item }">
        {{ item.data?.nextAuditYear || "-" }}
        <!-- {{ item.data?.rrsLevel ? `(${item.data?.rrsLevel})` : "" }} -->
      </template>
      <template #actions="{ item }">
        <v-icon
          @click="OpenFactorCalculationDialog(item)"
          class="pointer"
          :disabled="versionData.status != 0 || isFromSignOff()"
          >mdi-pencil</v-icon
        >
      </template>
    </material-table>

    <material-pagination
      :totalPages="totalPage"
      @pageFilter="pageFilter"
    ></material-pagination>

    <!-- 匯入資料 === Import Dialog === -->
    <dialog-import
      @close="importDialog.open = false"
      @reset="importDialog.errors = []"
      @submit="importSubmit"
      v-bind="importDialog"
    ></dialog-import>
    <!-- 查核因子風險計算 === Factor Calculation Dialog -->
    <factor-calculation
      v-if="factorCalculationDialog"
      :dialog="factorCalculationDialog"
      :factor="factorData"
      :allocationSettings="allocationSettings"
      @close="factorCalculationDialog = false"
      @save="saveFactor"
    ></factor-calculation>
  </v-container>
</template>
<script>
import FactorCalculation from "./components/FactorCalculation.vue";
import {
  apiUpdateRiskEvaluationStatus,
  apiGetRiskEvaluationById,
  apiDeleteRiskEvaluation,
  apiEditRiskEvaluationFactor,
} from "../../api/risk-evaluation";
import { apiGetAllocationSettingsById } from "../../api/allocation";
import { fromSignOffMixin, checkRolesMixin } from "@/helper";
export default {
  components: { FactorCalculation },
  mixins: [fromSignOffMixin, checkRolesMixin],
  data() {
    return {
      // --temp--
      versionData: {
        year: "2022",
        No: "01",
        unit: "車險商品部",
        status: 0,
      },
      riskEvaluationId: this.$route.params.id,
      // ----------------
      expanded: [],
      action: null,
      editMode: false,
      header: [
        { text: "風險項目", value: "item", sortable: false },
        { text: "查核因子", value: "factor", sortable: false },
        { text: "固有風險", value: "inherentRisk", sortable: false },
        { text: "控制措施", value: "controlMeasures", sortable: false },
        { text: "剩餘風險", value: "residualRisk", sortable: false },
        { text: "週期", value: "period", sortable: false },
        { text: "預計年份", value: "nextAuditYear" },
        { text: "操作", value: "actions", sortable: false, width: 50 },
      ],
      item_list: [
        // {
        //   id: 1,
        //   riskItem: "06.01.核保作業",
        //   rvFactor: "未依商品送審費率計收保費。",
        //   inherentRisk: 7.05,
        //   residualRisk: 6.526,
        //   controlMeasures: 0.598,
        //   final: 3,
        //   priod: 0.5,
        //   nextAuditYear: 2023,
        // },
        // {
        //   id: 2,
        //   riskItem: "06.01.核保作業",
        //   rvFactor: '車險未依"產險費率自由化第三階段配套措"規定實施費率調整。',
        //   inherentRisk: 7.05,
        //   residualRisk: 6.526,
        //   controlMeasures: 0.598,
        //   final: 3,
        //   priod: 0.5,
        //   nextAuditYear: 2023,
        // },
      ],
      // === Dialogs ===
      // 匯入資料
      importDialog: {
        // open: false,
        // type: "unitField",
        // errors: [],
      },
      // 查核因子風險計算
      factorCalculationDialog: false,
      factorData: {},
      // 查詢剩餘風險等級
      allocationId: null,
      allocationSettings: {},
      rrsLevel: null,
      rrsList: [],
      // 分頁
      page: 1,
      itemsPerPage: 10,
      totalPage: 1,
      options: {
        page: 1,
        sortBy: ["nextAuditYear"],
        sortDesc: [true],
      },
      // 風險項目
      riskItem: null,
      riskItems: [],

      defaultData: {
        si: null,
        ri: null,
        fi: null,
        pi: null,
        gr: null,
        rf: null,
        cr: null,
        ia: null,
        oa: null,
        ia1: null,
        ia2: null,
        ia3: null,
        ia4: null,
        ia5: null,
        ia6: null,
        oa1: null,
        oa2: null,
        oa3: null,
        oa4: null,
        irs: null,
        irsLevel: null,
        rrs: null,
        rrsLevel: null,
        cm: null,
        cmLevel: null,
        period: null,
        lastAuditYear: null,
        nextAuditYear: null,
        grade: null,
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getRiskEvaluation();
      },
    },
  },
  mounted() {
    this.getRiskItemsFilter();
  },
  methods: {
    // test(obj) {
    //   console.log({ obj });
    //   return JSON.parse(JSON.stringify(obj));
    // },
    async changeStatus(status) {
      try {
        let statusData = {
          status: status,
        };
        let res = await apiUpdateRiskEvaluationStatus(
          this.riskEvaluationId,
          statusData
        );
        console.log(res);
      } catch (error) {}
      this.versionData.status = status;
    },
    async getRiskEvaluation() {
      try {
        let searchData = {
          page: this.page,
          size: this.itemsPerPage,
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] == true ? "desc" : "asc",
          level: this.rrsLevel,
          item: this.riskItem,
        };
        let res = await apiGetRiskEvaluationById(
          this.riskEvaluationId,
          searchData
        );
        // this.$set(this.item_list, res.data.content);
        this.item_list = res.data.content;
        this.totalPage = res.data.totalPages;
        this.versionData = res.data.riskEvaluation;
        this.allocationId = res.data.riskEvaluation.allocationId;
        this.getAllocation();
      } catch (error) {}
    },
    // 取得剩餘風險等級
    async getAllocation() {
      try {
        let res = await apiGetAllocationSettingsById(this.allocationId);
        console.log(res);
        this.rrsList = res.data.content.rrs;
        this.allocationSettings = res.data.content;
      } catch (error) {
        console.log(error);
      }
    },
    handleExpand() {
      this.expanded = [];
      if (this.action == "allOpen") {
        for (let item of this.item_list) {
          this.expanded.push(item);
        }
      }
    },
    // === Import Dialog ===
    importDialogOpen() {
      this.importDialog = {
        open: true,
        type: "riskEvaluation",
      };
    },
    importSubmit() {
      console.log("submit");
      // TODO: Deal with submit
      // ------------
      //if Error
      this.importDialog = {
        ...this.importDialog,
        errors: ["格式錯誤", "缺少XX欄位"],
      };
    },
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.page = pageParams.page;
      this.itemsPerPage = pageParams.itemsPerPage;
      this.getRiskEvaluation();
    },
    OpenFactorCalculationDialog(item) {
      // TODO: get factor calculation by id
      console.log(this.versionData);
      let passData = {
        group: this.versionData.group,
      };
      this.factorData = { ...item, ...passData };
      console.log(this.factorData);
      if ([undefined, null, ""].includes(this.factorData.data)) {
        this.$set(this.factorData, "data", this.defaultData);
      }
      // --------------------
      this.factorCalculationDialog = true;
    },
    // 取得風險項目搜尋 list
    async getRiskItemsFilter() {
      try {
        let searchData = {
          page: 1,
          size: 999,
        };
        let res = await apiGetRiskEvaluationById(
          this.riskEvaluationId,
          searchData
        );
        console.log(res);
        for (let item of res.data.content) {
          console.log(item);
          if (
            ![undefined, null, ""].includes(item.item) &&
            this.riskItems.indexOf(item.item) === -1
          ) {
            this.riskItems.push(item.item);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveFactor() {
      try {
        this.factorData = arguments[0];
        console.log(this.factorData);

        let res = await apiEditRiskEvaluationFactor(
          this.riskEvaluationId,
          this.factorData.id,
          this.factorData.data
        );
        this.factorCalculationDialog = false;
        this.getRiskEvaluation();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.titleWidth {
  max-width: 520px;
  min-width: 450px;
}
.dividerWidth {
  min-width: 20px;
}
.tableSelectStyle {
  max-width: 70px;
  white-space: pre-line;
}
</style>
