<template>
  <material-dialog v-model="dialog" title="查核因子風險計算" width="960">
    <v-row style="width: 70%" class="mb-1 caption factorListText">
      <v-col>
        <span class="grey--text text--darken-1 mr-1">風險類型</span>
        {{ factorData.type }}
      </v-col>
      <v-col>
        <span class="grey--text text--darken-1 mr-1">風險項目</span>
        {{ factorData.item }}
      </v-col>
      <v-col>
        <span class="grey--text text--darken-1 mr-1">單位別</span>
        {{ factorData.group }}
      </v-col>
    </v-row>
    <div class="mb-5 caption factorListText">
      <span class="grey--text text--darken-1 mr-1">查核因子</span>
      {{ factorData.factor }}
    </div>
    <!-- 固有風險 IRS -->
    <v-row class="flex-nowrap mb-3" no-gutters>
      <v-col style="max-width: 150px">
        <v-card class="factorCard irs" outlined>
          <p class="text-h4 font-weight-bold">固有風險 IRS</p>
          <p class="text-h3 fubonGreen--text font-weight-bold">
            {{ FPTwoPlacesTrans(factorData.data?.irs) }}
          </p>
          <span class="grey--text text--darken-1 caption"
            >風險等級：<span class="error--text">{{
              factorData.data?.irsLevel
            }}</span></span
          >
        </v-card>
      </v-col>
      <!-- 固有風險 IRS (SI ~ GR) -->
      <v-col>
        <risk-evaluation-form-table
          :items="irsList"
          :action="false"
          bgColor="#FAFAFA"
        >
          <template v-for="title in irsTemplateList" #[`${title}`]>
            <div class="riskCard pt-2" :key="title">
              <p class="fubonGreen--text text-subtitle-1">
                {{ FPTwoPlacesTrans(factorData.data[title]) }}
              </p>
              <v-select
                outlined
                dense
                v-model="factorData.data[title]"
                class="w-90 inputWhiteBg"
                label="請選擇"
                single-line
                hide-details
                :items="allocationSettings[title]"
                :menu-props="{ offsetY: true }"
                item-value="value"
                @change="calculateIRS"
              ></v-select>
            </div>
          </template>
        </risk-evaluation-form-table>
      </v-col>
    </v-row>
    <!-- 控制措施 CM -->
    <v-row class="flex-nowrap" no-gutters>
      <v-col style="max-width: 150px">
        <v-card class="factorCard" outlined>
          <p class="text-h4 font-weight-bold">控制措施 CM</p>
          <p class="text-h3 fubonGreen--text font-weight-bold">
            {{ FPTwoPlacesTrans(factorData.data?.cm) }}
          </p>
          <span class="grey--text text--darken-1 caption"
            >風險等級：<span class="error--text">
              {{ factorData.data?.cmLevel }}</span
            ></span
          >
        </v-card>
      </v-col>
      <v-col
        class="cmZone"
        :style="`max-width:${isExpand ? '260px' : '300px'}`"
      >
        <!-- 控制措施 CM (RF ~ CR)-->
        <risk-evaluation-form-table
          :items="cmList"
          :action="false"
          bgColor="#FAFAFA"
          noPadding
        >
          <template #rf>
            <div class="riskCard noPaddingCard cm py-2">
              <p class="fubonGreen--text text-subtitle-1">
                {{ FPTwoPlacesTrans(factorData.data?.rf) }}
              </p>
              <div class="px-2">
                <v-select
                  v-model="factorData.data.rf"
                  outlined
                  dense
                  class="inputWhiteBg"
                  label="請選擇"
                  single-line
                  hide-details
                  :menu-props="{ offsetY: true }"
                  :items="allocationSettings['rf']"
                  item-value="value"
                  @change="calculateCM('rf')"
                ></v-select>
              </div>
            </div>
          </template>
          <template #cr>
            <div class="riskCard noPaddingCard cm py-2">
              <p class="fubonGreen--text text-subtitle-1">
                {{ FPTwoPlacesTrans(factorData.data?.cr) }}
              </p>
              <p class="caption mx-2">{{ crLevel }}</p>
            </div>
          </template>
        </risk-evaluation-form-table>
        <v-btn
          small
          @click="isExpand = !isExpand"
          fab
          class="elevation-1 btnExpand"
          color="#fff"
        >
          <v-icon v-if="!isExpand" color="fubonBlue">mdi-chevron-right</v-icon>
          <v-icon v-else color="fubonBlue">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col :class="isExpand ? 'active' : ''" class="expand">
        <div class="d-flex h-100">
          <!-- 控制措施 CM (IA-OA)-->
          <risk-evaluation-form-table
            :items="[{ text: 'IA-OA', value: 'IA-OA' }]"
            :action="false"
            color="rgba(0, 149, 184, 0.2)"
            textColor="#444"
            minWidth="60"
            class="yValue"
            smTitleBold
            noPadding
          >
            <template #IA-OA>
              <div class="riskCard noPaddingCard text-subtitle-2">
                {{ FPTwoPlacesTrans(factorData.data.ia - factorData.data.oa) }}
              </div>
            </template>
          </risk-evaluation-form-table>

          <div>
            <!-- 控制措施 CM (IA)-->
            <div class="d-flex">
              <risk-evaluation-form-table
                :items="[{ text: 'IA', value: 'IA' }]"
                :action="false"
                color="rgba(0, 149, 184, 0.2)"
                textColor="#444"
                minWidth="60"
                bgColor="#FAFAFA"
                smTitleBold
                noPadding
              >
                <template #IA>
                  <div class="riskCard py-3 text-subtitle-2 mb-0">
                    {{ FPTwoPlacesTrans(factorData.data.ia) }}
                  </div>
                </template>
              </risk-evaluation-form-table>
              <!-- 控制措施 CM (IA1~6)-->
              <risk-evaluation-form-table
                :items="iaList"
                :action="false"
                color="#CACACA"
                textColor="#444"
                bgColor="#E8E8E8"
                minWidth="40"
                smTitle
              >
                <template
                  v-for="(title, index) in iaTemplateList"
                  #[`${title}`]
                >
                  <v-select
                    v-model="factorData.data['ia' + (index + 1)]"
                    :key="title"
                    outlined
                    dense
                    class="inputWhiteBg smSelect"
                    label="選擇"
                    single-line
                    hide-details
                    :items="allocationSettings['ia'][index]?.content"
                    :menu-props="{ offsetY: true }"
                    item-value="value"
                    @change="calculateIA"
                  >
                  </v-select>
                </template>
              </risk-evaluation-form-table>
            </div>
            <!-- 控制措施 CM (OA)-->
            <div class="d-flex">
              <div style="width: 60px">
                <risk-evaluation-form-table
                  :items="[{ text: 'OA', value: 'OA' }]"
                  :action="false"
                  color="rgba(0, 149, 184, 0.2)"
                  textColor="#444"
                  minWidth="60"
                  smTitle
                  bgColor="#FAFAFA"
                  noPadding
                  smTitleBold
                >
                  <template #OA>
                    <div class="riskCard text-subtitle-2 mb-0 py-3">
                      {{ FPTwoPlacesTrans(factorData.data.oa) }}
                    </div>
                  </template>
                </risk-evaluation-form-table>
              </div>
              <!-- 控制措施 CM (OA1~4)-->
              <risk-evaluation-form-table
                :items="oaList"
                :action="false"
                color="#CACACA"
                textColor="#444"
                bgColor="#E8E8E8"
                minWidth="80"
                smTitle
              >
                <template
                  v-for="(title, index) in oaTemplateList"
                  #[`${title}`]
                >
                  <v-select
                    v-model="factorData.data['oa' + (index + 1)]"
                    :key="title"
                    outlined
                    dense
                    class="inputWhiteBg smSelect"
                    label="請選擇"
                    single-line
                    hide-details
                    :menu-props="{ offsetY: true }"
                    :items="allocationSettings['oa'][index]?.content"
                    item-value="value"
                    @change="calculateOA"
                  >
                  </v-select>
                </template>
              </risk-evaluation-form-table>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-3"></v-divider>
    <!-- 剩餘風險 RRS -->
    <v-row class="flex-nowrap" no-gutters>
      <v-col style="max-width: 150px">
        <v-card class="factorCard rrs" outlined>
          <p class="text-h4 font-weight-bold">剩餘風險 RRS</p>
          <p class="text-h3 fubonGreen--text font-weight-bold">
            {{ FPTwoPlacesTrans(factorData.data?.rrs) }}
          </p>
          <span class="grey--text text--darken-1 caption"
            >風險等級：<span class="error--text">{{
              factorData.data?.rrsLevel
            }}</span></span
          >
        </v-card>
      </v-col>
      <v-col class="ma-3">
        <p>
          <span class="grey--text text--darken-1 mr-3">稽核週期</span
          >{{ factorData.data?.period || "-" }} 年
        </p>
        <div class="d-flex mb-4 align-center">
          <span class="grey--text text--darken-1 mr-3">上次查核</span>
          <div class="w-10" style="min-width: 60px">
            <v-text-field
              v-model="factorData.data.lastAuditYear"
              outlined
              dense
              hide-details
              single-line
              @change="calculNextYear"
            ></v-text-field>
          </div>
        </div>
        <p>
          <span class="grey--text text--darken-1 mr-3">預計查核</span
          >{{ factorData.data.nextAuditYear || "-" }}
        </p>
      </v-col>
      <v-col>
        <div class="directions">
          <p>公式說明:</p>
          <p>IRS=(SI+RI+FI)*PI*GR</p>
          <p>CM=RF*CR；CR為(IA-OA)=Y經轉換所得之等級</p>
          <p>RRS=CM*IRS</p>
        </div>
      </v-col>
    </v-row>
    <template #actions>
      <v-spacer></v-spacer>
      <btn-cancel @click="$emit('close')">取消</btn-cancel>
      <v-btn @click="saveFactor" depressed color="primary"> 確認 </v-btn>
    </template>
  </material-dialog>
</template>
<script>
import RiskEvaluationFormTable from "./RiskEvaluationFormTable.vue";
import { floatCalculatorMixin, FPMixin } from "../../../helper";
export default {
  components: { RiskEvaluationFormTable },
  mixins: [floatCalculatorMixin, FPMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    factor: {
      type: Object,
      default: {},
    },
    allocationSettings: {
      type: Object,
      default: {
        si: [],
        ri: [],
        fi: [],
        pi: [],
        gr: [],
        rf: [],
        grDesc: "",
        rfDesc: "",
        ia: [
          {
            text: "內稽",
            weight: null,
            content: [],
          },
          {
            text: "風控",
            weight: null,
            content: [],
          },
          {
            text: "法遵",
            weight: null,
            content: [],
          },
          {
            text: "內控自評",
            weight: null,
            content: [],
          },
          {
            text: "內控運作",
            weight: null,
            content: [],
          },
          {
            text: "重大風險事件",
            weight: null,
            content: [],
          },
        ],
        oa: [
          {
            text: "主管機關裁罰",
            content: [],
          },
          {
            text: "主管機關意見",
            content: [],
          },
          {
            text: "其他(含會計師)",
            content: [],
          },
          {
            text: "金控缺失",
            content: [],
          },
        ],
      },
    },
  },
  data() {
    return {
      isExpand: false,
      factorData: {
        riskType: "保險風險",
        riskItem: "核保作業",
        unit: "車險商品部",
        factor: '車險未依"產險費率自由化第三階段配套措施"規定實施費率調整。',
        data: {},
        SI: "3.00",
        RI: "2.50",
        FI: "1.50",
        PI: "0.80",
        GR: "1.00",
        RF: "1.05",
        CR: "3.00",
        IA: "3.00",
        OA: "3.00",
        IA1: "3.00",
        IA2: "3.00",
        IA3: "3.00",
        IA4: "3.00",
        IA5: "3.00",
        IA6: "3.00",
        OA1: "3.00",
        OA2: "3.00",
        OA3: "3.00",
        OA4: "3.00",
        IRS: "3.00",
        RRS: "3.00",
        CM: "3.00",
        period: 2, //稽核週期
        lastAudit: "2021", // 上次查核
        nextAudit: "2023", //預計查核
        grade: 1,
      },
      irsList: [
        { text: "SI", value: "si" },
        { text: "RI", value: "ri" },
        { text: "FI", value: "fi" },
        { text: "PI", value: "pi" },
        { text: "GR", value: "gr" },
      ],
      cmList: [
        { text: "RF", value: "rf" },
        { text: "CR", value: "cr" },
      ],
      iaList: [
        { text: "內稽", value: "ia1" },
        { text: "風管", value: "ia2" },
        { text: "法遵", value: "ia3" },
        { text: "自評", value: "ia4" },
        { text: "運作", value: "ia5" },
        { text: "重大", value: "ia6" },
      ],
      oaList: [
        { text: "裁罰", value: "oa1" },
        { text: "主管機關", value: "oa2" },
        { text: "其他", value: "oa3" },
        { text: "金控", value: "oa4" },
      ],
      crLevel: null,
    };
  },
  mounted() {
    this.factorData = this.factor;
    // this.calculateCM("cr");
    this.formatValueToNumber();
  },
  methods: {
    // 計算IRS
    calculateIRS() {
      let irsCount = 0;
      irsCount = this.floatAdd(
        this.factorData.data.si,
        this.factorData.data.ri
      );
      irsCount = this.floatAdd(irsCount, this.factorData.data?.fi);
      irsCount = this.floatMul(irsCount, this.factorData.data?.pi);
      irsCount = this.floatMul(irsCount, this.factorData.data?.gr);
      this.factorData.data.irs = irsCount;
      this.factorData.data.irsLevel = this.getScoreGrade(
        "inherentRisk",
        irsCount
      );
      this.calculateRRS();
    },
    // 計算CM
    calculateCM(type) {
      if (type == "cr") {
        let crCount = 0;
        crCount = this.floatSub(
          this.factorData.data.ia,
          this.factorData.data.oa
        );
        console.log(crCount);
        this.crLevel = this.getScoreGrade("cm", crCount);
        console.log(this.crLevel);
        if ([undefined, null, ""].includes(this.crLevel)) {
          this.factorData.data.cr = "-";
        }
      }
      let cmCount = 0;
      cmCount = this.floatMul(this.factorData.data.rf, this.factorData.data.cr);
      this.factorData.data.cm = cmCount;
      this.factorData.data.cmLevel = this.getScoreGrade(
        "controlMeasure",
        cmCount
      );
      this.calculateRRS();
    },
    calculateIA() {
      let iaCount = 0;
      for (let [index, item] of this.allocationSettings["ia"].entries()) {
        iaCount = this.floatAdd(
          iaCount,
          this.floatMul(item.weight, this.factorData.data["ia" + (index + 1)])
        );
      }
      this.factorData.data.ia = iaCount;
      this.calculateCM("cr");
    },
    calculateOA() {
      let oaCount = 0;
      for (let [index, item] of this.allocationSettings["oa"].entries()) {
        oaCount = this.floatAdd(
          oaCount,
          this.factorData.data["oa" + (index + 1)]
        );
      }
      this.factorData.data.oa = oaCount;
      this.calculateCM("cr");
    },
    calculateRRS() {
      this.factorData.data.rrs = this.floatMul(
        this.factorData.data.cm,
        this.factorData.data.irs
      );
      this.factorData.data.rrsLevel = this.getScoreGrade(
        "rrs",
        this.factorData.data.rrs
      );
    },
    getScoreGrade(key, score) {
      for (let [index, item] of this.allocationSettings[key].entries()) {
        if (
          (item.min <= Number(score) && item.max >= Number(score)) ||
          (index == this.allocationSettings[key].length - 1 &&
            Number(score) >= item.max) ||
          (index == 0 && Number(score) <= item.min)
        ) {
          if (["inherentRisk", "controlMeasure", "rrs"].includes(key)) {
            if (key == "rrs") {
              // this.factorData.data.period = 2;
              this.factorData.data.period = item.cycle;
              this.calculNextYear();
              return item.level;
            }
            return item.grade;
          } else if (key == "cm") {
            this.factorData.data.cr = item.cr;

            return item.type;
          }
        }
      }
    },
    calculNextYear() {
      if ([undefined, null, ""].includes(this.factorData.data.lastAuditYear)) {
        this.factorData.data.nextAuditYear = 0;
      } else {
        this.factorData.data.nextAuditYear = Math.ceil(
          this.floatAdd(
            this.factorData.data.lastAuditYear,
            this.factorData.data.period
          )
        );
      }
    },
    saveFactor() {
      this.$emit("save", this.factorData);
    },
    // 更新值為數字
    formatValueToNumber() {
      for (let key in this.factorData.data) {
        if (
          ![
            "lastAuditYear",
            "nextAuditYear",
            "grade",
            "irsLevel",
            "rrsLevel",
            "cmLevel",
          ].includes(key)
        ) {
          this.factorData.data[key] = Number(this.factorData.data[key]);
        }
      }
    },
  },
  computed: {
    irsTemplateList() {
      return this.irsList.map((el) => el.value);
    },
    iaTemplateList() {
      return this.iaList.map((el) => el.value);
    },
    oaTemplateList() {
      return this.oaList.map((el) => el.value);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.factorListText {
  font-size: 14px !important;
}
.factorCard {
  border-radius: 0;
  @include boxSize(140px);
  @include flexCenter;
  flex-direction: column;
  margin-bottom: 12px;
  &.irs {
    height: 100%;
  }
  &.rrs {
    border: 1px solid $fubonGreen;
    background-color: rgba($fubonGreen, 0.05);
  }
}
.riskCard {
  @include flexCenter;
  flex-direction: column;
  &.noPaddingCard {
    min-height: 80%;
  }
}
.cmZone {
  max-width: 300px;
  position: relative;
  transition: all 0.2s ease-in-out;
  .btnExpand {
    @include boxSize(18px);
    position: absolute;
    right: 4px;
    top: 12px;
  }
}
.yValue {
  border: 2px solid $fubonBlue;
  z-index: 1;
}
.expand {
  max-width: 0;
  // height: 100px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}
.active {
  max-width: 100%;
}
.smSelect {
  &:deep(.v-input__slot) {
    padding: 0 0 0 2px !important;
    height: 20px !important;
    label {
      font-size: 12px;
    }
  }
}
.directions {
  background-color: #fff7e8;
  @include boxSize(400px, 150px);
  margin-left: auto;
  padding: 16px;
  p {
    margin-bottom: 6px;
    font-size: 12px;
  }
}
</style>
